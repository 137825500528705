<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <h1 class="title-h1">DEVELOPMENT</h1>
        </div>
        <div class="title-middle">
          <img src="../assets/devHistory/icon.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">PATH</h1>
        </div>
        <div class="title-bottom">
          发展历程
        </div>
      </div>
      <!-- <div class="path">
        <el-timeline>
          <el-timeline-item>
            <div class="relative">
              <div class="timestamp">{{activities[0].timestamp}}</div>
              <div>{{activities[0].content}}</div>
            </div>
          </el-timeline-item>

          <el-timeline-item>
            <div class="timestamp">{{activities[1].timestamp}}</div>
            <div>{{activities[1].content}}</div>
          </el-timeline-item>

          <el-timeline-item>
            <div class="relative">
              <div class="timestamp">{{activities[2].timestamp}}</div>
              <div>{{activities[2].content}}</div>
              <div>{{activities[2].paragraph2}}</div>
            </div>
          </el-timeline-item>

          <el-timeline-item>
            <div class="timestamp">{{activities[3].timestamp}}</div>
            <div>{{activities[3].content}}</div>
            <div>{{activities[3].paragraph2}}</div>
          </el-timeline-item>
          <el-timeline-item>
            <div class="relative">
              <div class="timestamp">{{activities[4].timestamp}}</div>
              <div>{{activities[4].content}}</div>
              <div>{{activities[4].paragraph2}}</div>
              <div>{{activities[4].paragraph3}}</div>
              <div>{{activities[4].paragraph4}}</div>
            </div>
          </el-timeline-item>
          <el-timeline-item>
            <div class="timestamp">{{activities[5].timestamp}}</div>
            <div>{{activities[5].content}}</div>
            <div>{{activities[5].paragraph2}}</div>
          </el-timeline-item>
          <el-timeline-item>
            <div class="timestamp">{{activities[6].timestamp}}</div>
            <div>{{activities[6].content}}</div>
          </el-timeline-item>
          <el-timeline-item>
            <div class="timestamp">{{activities[7].timestamp}}</div>
            <div>{{activities[7].content}}</div>
            <div>{{activities[7].paragraph2}}</div>
          </el-timeline-item>
          <el-timeline-item>
            <div>{{activities[8].content}}</div>
          </el-timeline-item>

        </el-timeline>
      </div> -->

      <div class="time-line">
        <div class="left-item">
          <div class="left-tail"></div>
          <div class="left-oval"></div>
          <div class="left-content">
            <div class="right-title">2013年</div>
            <div class="right-text">公司成立并成为国内知名药企的荷尔蒙原料供应商。</div>
          </div>
        </div>
        <div class="item">
          <div class="tail"></div>
          <div class="oval"></div>
          <div class="right-content">
            <div class="right-title">2014-2015年</div>
            <div class="right-text">获评国家级高新技术企业、厦门市“双百计划”企业。</div>
          </div>
        </div>

        <div class="left-item">
          <div class="left-tail"></div>
          <div class="left-oval"></div>
          <div class="left-content">
            <div class="right-title">2016-2017年</div>
            <div class="right-text">烯丙孕素等产品研发成功；</div>
            <div class="right-text">获评国家高新技术企业、厦门市高新技术企业、科技小巨人企业等称号。</div>
          </div>
        </div>

        <div class="item relative">
          <!-- <div class="tail"></div> -->
          <div class="oval"></div>
          <div class="right-content">
            <div class="right-title">2018年</div>
            <div class="right-text">绒促性素顺利通过农业部GMP验收，取得品种批文；</div>
            <div class="right-text">获科技型中小企业等称号。</div>
          </div>
        </div>

        <div class="left-item">
          <div class="left-tail"></div>
          <div class="left-oval"></div>
          <div class="left-content"
               style="padding-bottom: 30%;">
            <div class="right-title">2019年</div>
            <div class="right-text">新建4个GMP车间；</div>
            <div class="right-text">顺利通过氯前列醇、氯前列醇钠、多肽产品（戈那瑞林、促黄体素释放激素A2、促黄体素释放激素A3）、地诺前列腺素（氨基丁三醇前列腺素F2α）的GMP验收并获得生产许可证；</div>
            <div class="right-text">为扩大企业产能，在厦门火炬高新区扩增生产场地，并于河南驻马店医药园区设立子公司——河南美森药业有限公司（53391㎡），新建生化原料生产基地；</div>
            <div class="right-text">入选为厦门市“三高”（高技术、高成长、高附加值）企业，获评中国最具成长性企业等；“聚合剂多肽在HCG生产中的应用”被认定为厦门市高新技术成果转化项目。</div>
          </div>
        </div>

        <div class="item relative-2020">
          <!-- <div class="tail"></div> -->
          <div class="oval"></div>
          <div class="right-content">
            <div class="right-title">2020年</div>
            <div class="right-text">获得烯丙孕素、地诺前列腺素科技成果转化证书；</div>
            <div class="right-text">荣获厦门市科技小巨人领军企业、专精特新企业称号等荣誉。</div>
          </div>
        </div>

        <div class="item relative-2021">
          <!-- <div class="tail"></div> -->
          <div class="oval"></div>
          <div class="right-content">
            <div class="right-title">2021年</div>
            <div class="right-text">顺利通过了D-氯前列醇钠、布舍瑞林、烯丙孕素GMP认证。</div>
          </div>
        </div>

        <div class="item relative-2022">
          <!-- <div class="tail"></div> -->
          <div class="oval"></div>
          <div class="right-content">
            <div class="right-title">2022年</div>
            <div class="right-text">荣获厦门市第一批专精特新“小巨人”后备工业企业、福建省“专精特新”中小企业、厦门市未来产业骨干企业等荣誉；</div>
            <div class="right-text">“光学纯氯前列醇的制备与纯化”被认定为厦门市高新技术成果转化项目。</div>
          </div>
        </div>

        <div class="item relative-future">
          <!-- <div class="tail"></div> -->
          <div class="oval"></div>
          <div class="right-content">
            <div class="right-text">未来可期......</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'devHistory',
  data () {
    return {
      activities: [{
        timestamp: '2013年',
        content: '公司成立并成为国内知名药企的荷尔蒙原料供应商。'
      }, {
        timestamp: '2014-2015年',
        content: '获评国家级高新技术企业、厦门市“双百计划”企业。'
      }, {
        timestamp: '2016-2017年',
        content: '烯丙孕素等产品研发成功；',
        paragraph2: '获评国家高新技术企业、厦门市高新技术企业、科技小巨人企业等称号。'

      }, {
        timestamp: '2018年',
        content: '绒促性素顺利通过农业部GMP验收，取得品种批文；',
        paragraph2: '获科技型中小企业等称号。'
      }, {
        timestamp: '2019年',
        content: '新建4个GMP车间；',
        paragraph2: '顺利通过氯前列醇、氯前列醇钠、多肽产品（戈那瑞林、促黄体素释放激素A2、促黄体素释放激素A3）、地诺前列腺素（氨基丁三醇前列腺素F2α）的GMP验收并获得生产许可证；',
        paragraph3: '为扩大企业产能，在厦门火炬高新区扩增生产场地，并于河南驻马店医药园区设立子公司——河南美森药业有限公司（53391㎡），新建生化原料生产基地；',
        paragraph4: '入选为厦门市“三高”（高技术、高成长、高附加值）企业，获评中国最具成长性企业等；“聚合剂多肽在HCG生产中的应用”被认定为厦门市高新技术成果转化项目。'
      }, {
        timestamp: '2020年',
        content: '获得烯丙孕素、地诺前列腺素科技成果转化证书；',
        paragraph2: '荣获厦门市科技小巨人领军企业、专精特新企业称号等荣誉。'
      }, {
        timestamp: '2021年',
        content: '顺利通过了D-氯前列醇钠、布舍瑞林、烯丙孕素GMP认证。'
      }, {
        timestamp: '2022年',
        content: '荣获厦门市第一批专精特新“小巨人”后备工业企业、福建省“专精特新”中小企业、厦门市未来产业骨干企业等荣誉；',
        paragraph2: '“光学纯氯前列醇的制备与纯化”被认定为厦门市高新技术成果转化项目。'
      }, {
        content: '未来可期......'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    color: #fff;
    height: 100%;
    padding: 22% 15% 10%;
    background: url("../assets/devHistory/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 15%;
      box-sizing: border-box;
      .img-icon {
        height: 50px;
        padding-right: 6%;
      }
      .title-top,
      .title-middle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
      }
    }
    .path {
      display: flex;
      justify-content: center;
      padding-left: 50%;
      width: 40%;
      padding-bottom: 30%;
      ::v-deep .el-timeline {
        font-size: 17px;
      }
      ::v-deep .el-timeline-item__content {
        color: #fff;
      }
      ::v-deep .el-timeline-item__timestamp {
        color: #fff;
      }
      .relative {
        position: relative;
        left: -110%;
        text-align: end;
      }
      .right-relative {
        position: absolute;
        top: 264px;
      }
    }

    .time-line {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      .item {
        margin: 0 0 0 40%;
        width: 50%;
        margin-bottom: 11px;
        position: relative;
        .oval {
          position: absolute;
          left: -5px;
          background-color: #e4e7ed;
          border-radius: 50%;
          width: 12px;
          height: 12px;
        }

        .tail {
          position: absolute;
          top: 12px;
          height: 100%;
          border-left: 2px solid #e4e7ed;
        }

        .right-content {
          padding-left: 28px;
        }
      }
      .right-title {
        font-size: 32px;
      }
      .right-text {
        font-size: 17px;
      }
      .left-item {
        width: 40.1%;
        margin-bottom: 11px;
        position: relative;
        .left-oval {
          position: absolute;
          right: -5px;
          background-color: #e4e7ed;
          border-radius: 50%;
          width: 12px;
          height: 12px;
        }

        .left-tail {
          position: absolute;
          top: 12px;
          right: 0;
          height: 100%;
          border-left: 2px solid #e4e7ed;
        }

        .left-content {
          text-align: end;
          padding-right: 28px;
        }
      }
      .relative {
        position: absolute;

        top: 30%;
      }

      .relative-2020 {
        position: absolute;

        top: 44%;
      }

      .relative-2021 {
        position: absolute;

        top: 60%;
      }

      .relative-2022 {
        position: absolute;

        top: 75%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .container {
    .content {
      padding: 22% 6% 5%;
      box-sizing: border-box;
      .title {
        padding-top: 20%;
        .title-top {
          .title-h1 {
            font-size: 28px;
          }
        }
        .title-middle {
          .title-h1 {
            font-size: 28px;
          }
        }
        .title-bottom {
          font-size: 22px;
          font-weight: 600;
        }
        #title {
          font-size: 22px;
          font-weight: 600;
        }
        .img-icon {
          width: 30px;
          height: 30px;
        }
      }
      .path {
        padding-left: 17%;
        justify-content: flex-end;
        width: 100%;
        box-sizing: border-box;
        .timestamp {
          font-size: 18px;
        }
        ::v-deep .el-timeline {
          width: 65%;
          font-size: 14px;
        }
        .relative {
          left: -121%;
        }
      }
      .time-line {
        .left-item {
          width: 50%;
          .left-content {
            padding-bottom: 5%;
          }
        }
        .item {
          margin: 0 0 0 49.5%;
        }
        .right-title {
          font-size: 18px;
        }
        .right-text {
          font-size: 14px;
        }
        .relative-2020 {
          top: 43%;
        }
        .relative-2022 {
          position: absolute;

          top: 71%;
        }
      }
    }
  }
}
</style>